import {RouteInfo} from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '/admin/dashboard/dashboard2',
    title: 'DASHBOARD',
    moduleName: ['dashboard'],
    iconType: 'material-icons-two-tone',
    imgSrc: 'assets/images/sidebar-logo/open-arrow.svg',
    icon: 'home',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    imgArrowDownSrc: '',
    imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
    submenu: [],
  },
  {
    path: '/',
    title: 'ITEM CODE',
    moduleName: ['helpdesk-query'],
    iconType: 'material-icons-two-tone',
    icon: 'event_note',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    imgSrc: 'assets/images/sidebar-logo/open-arrow.svg',
    imgArrowDownSrc: 'assets/images/sidebar-logo/arrow-bar-down.svg',
    imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
    submenu: [
      {
        path: './inventory/trn-inv-non-coded-pur-request/all-trn-inv-non-coded-pur-requests/crud',
        title: 'Request for NIV Items',
        moduleName: ['helpdesk-query'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './inventory/trn-inv-non-coded-pur-request/all-trn-inv-non-coded-pur-requests/approve',
        title: 'Approve Request for NIV Items',
        moduleName: ['helpdesk-query'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      }
    ],
  },
  {
    path: '/',
    title: 'PURCHASE DEMAND',
    moduleName: ['helpdesk-query'],
    iconType: 'material-icons-two-tone',
    icon: 'event_note',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    imgSrc: 'assets/images/sidebar-logo/open-arrow.svg',
    imgArrowDownSrc: 'assets/images/sidebar-logo/arrow-bar-down.svg',
    imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
    submenu: [
      {
        path: './inventory/trn-inv-coded-pur-request/all-trn-inv-coded-pur-requests/crud',
        title: 'Purchase Requests',
        moduleName: ['helpdesk-query'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './inventory/trn-inv-work-order-request/all-trn-inv-work-order-requests/crud',
        title: 'Request for Work Orders',
        moduleName: ['helpdesk-query'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './inventory/trn-inv-coded-pur-request/all-trn-inv-coded-pur-requests/approve',
        title: 'Approve Purchase Requests',
        moduleName: ['helpdesk-query'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './inventory/trn-inv-work-order-request/all-trn-inv-work-order-requests/approve',
        title: 'Approve Request for Work Orders',
        moduleName: ['helpdesk-query'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      }
    ],
  },
  {
    path: './',
    title: 'PROCUREMENT',
    moduleName: ['master'],
    iconType: 'material-icons-two-tone',
    icon: 'event_note',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    imgSrc: 'assets/images/sidebar-logo/open-arrow.svg',
    imgArrowDownSrc: 'assets/images/sidebar-logo/arrow-bar-down.svg',
    imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
    submenu: [
      {
        path: './inventory/trn-inv-coded-quotation-request/all-trn-inv-coded-quotation-requests/crud',
        title: 'Quotation Request',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './inventory/trn-inv-work-order-quotation-request/all-trn-inv-work-order-quotation-requests/crud',
        title: 'Quotation Request for Work Order',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './inventory/trn-inv-coded-quotation-request/all-trn-inv-coded-quotation-requests/approve',
        title: 'Approve Quotation Request',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './inventory/trn-inv-work-order-quotation-request/all-trn-inv-work-order-quotation-requests/approve',
        title: 'Approve Quotation Request for Work Order',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      }
    ]
  },
  {
    path: './',
    title: 'PURCHASE COMMITTEE',
    moduleName: ['master'],
    iconType: 'material-icons-two-tone',
    icon: 'event_note',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    imgSrc: 'assets/images/sidebar-logo/open-arrow.svg',
    imgArrowDownSrc: 'assets/images/sidebar-logo/arrow-bar-down.svg',
    imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
    submenu: [
      {
        path: './inventory/trn-inv-quotation-pre-negotiation/all-trn-inv-quotation-pre-negotiations/assign',
        title: 'Comparative Statement',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './inventory/trn-inv-quotation/all-trn-inv-quotations/crud',
        title: 'Proposal for PC',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './master/sub-module/all-sub-modules',
        title: 'Approved PC Proposal',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './inventory/trn-inv-wo-quotation/all-trn-inv-wo-quotations/crud',
        title: 'WO Comparative Statement',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './inventory/trn-inv-quotation/all-trn-inv-wo-quotations/crud',
        title: 'WO Proposal for PC',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './master/sub-module/all-sub-modules',
        title: 'WO Approved PC Proposal',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      }
    ]
  },
  {
    path: './',
    title: 'PURCHASE ORDER',
    moduleName: ['master'],
    iconType: 'material-icons-two-tone',
    icon: 'event_note',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    imgSrc: 'assets/images/sidebar-logo/open-arrow.svg',
    imgArrowDownSrc: 'assets/images/sidebar-logo/arrow-bar-down.svg',
    imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
    submenu: [
      {
        path: './inventory/trn-inv-pur-order/all-trn-inv-pur-orders/crud',
        title: 'Generate PO (ARC/Non ARC)',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './inventory/trn-inv-pur-order/all-trn-inv-pur-orders/approve',
        title: 'Approve PO (ARC/Non ARC)',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './master/sub-module/all-sub-modules',
        title: 'View PO (ARC/Non ARC)\n',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      }
    ]
  },
  {
    path: './',
    title: 'WORK ORDER',
    moduleName: ['master'],
    iconType: 'material-icons-two-tone',
    icon: 'event_note',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    imgSrc: 'assets/images/sidebar-logo/open-arrow.svg',
    imgArrowDownSrc: 'assets/images/sidebar-logo/arrow-bar-down.svg',
    imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
    submenu: [
      {
        path: './inventory/trn-inv-work-order/all-trn-inv-work-orders/crud',
        title: 'Generate WO (ARC/Non ARC)',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './master/sub-module/all-sub-modules',
        title: 'View PO (ARC/Non ARC)\n',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      }
    ]
  },
  {
    path: './',
    title: 'GOODS DELIVERY NOTE',
    moduleName: ['master'],
    iconType: 'material-icons-two-tone',
    icon: 'event_note',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    imgSrc: 'assets/images/sidebar-logo/open-arrow.svg',
    imgArrowDownSrc: 'assets/images/sidebar-logo/arrow-bar-down.svg',
    imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
    submenu: [
      {
        path: './inventory/trn-inv-gdn/all-trn-inv-gdns/crud',
        title: 'Generate GDN',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './inventory/trn-inv-gdn/all-trn-inv-gdns/approve',
        title: 'Approve GDN',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      }
    ]
  },
  {
    path: './',
    title: 'GOODS RECEIPT NOTE',
    moduleName: ['master'],
    iconType: 'material-icons-two-tone',
    icon: 'event_note',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    imgSrc: 'assets/images/sidebar-logo/open-arrow.svg',
    imgArrowDownSrc: 'assets/images/sidebar-logo/arrow-bar-down.svg',
    imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
    submenu: [
      {
        path: './inventory/trn-inv-grn/all-trn-inv-grns/crud',
        title: 'Issue GRN',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './inventory/trn-inv-grn/all-trn-inv-grns/approve',
        title: 'Update GRN / Add Stock',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      }
    ]
  },
  {
    path: './',
    title: 'ITEM EXCHANGE',
    moduleName: ['master'],
    iconType: 'material-icons-two-tone',
    icon: 'event_note',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    imgSrc: 'assets/images/sidebar-logo/open-arrow.svg',
    imgArrowDownSrc: 'assets/images/sidebar-logo/arrow-bar-down.svg',
    imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
    submenu: [
      {
        path: './inventory/trn-inv-item-indent/all-trn-inv-item-indents/crud',
        title: 'Generate Indent',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './inventory/trn-inv-item-indent/all-trn-inv-item-indents/approve',
        title: 'Approve Indent',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './inventory/trn-inv-item-issue/all-trn-inv-item-issues/crud',
        title: 'Issue Item',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './inventory/trn-inv-item-receive/all-trn-inv-item-receives/crud',
        title: 'Receive Item',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './inventory/trn-inv-item-return/all-trn-inv-item-returns/crud',
        title: 'Return Item',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      }
    ]
  },
  {
    path: './',
    title: 'ITEM EXCHANGE Sub <-> Sub',
    moduleName: ['master'],
    iconType: 'material-icons-two-tone',
    icon: 'event_note',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    imgSrc: 'assets/images/sidebar-logo/open-arrow.svg',
    imgArrowDownSrc: 'assets/images/sidebar-logo/arrow-bar-down.svg',
    imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
    submenu: [
      {
        path: './master/module/all-modules',
        title: 'Generate Indent',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './master/sub-module/all-sub-modules',
        title: 'Approve Indent',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './master/sub-module/all-sub-modules',
        title: 'Issue Item',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './master/sub-module/all-sub-modules',
        title: 'Receive Item',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './master/sub-module/all-sub-modules',
        title: 'Return Item',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      }
    ]
  },
  {
    path: './',
    title: 'END USER CONSUMPTION\n',
    moduleName: ['master'],
    iconType: 'material-icons-two-tone',
    icon: 'event_note',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    imgSrc: 'assets/images/sidebar-logo/open-arrow.svg',
    imgArrowDownSrc: 'assets/images/sidebar-logo/arrow-bar-down.svg',
    imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
    submenu: [
      {
        path: './master/module/all-modules',
        title: 'End user consumtion\n',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      }
    ]
  },
  {
    path: './',
    title: 'STOCK ADJUSTMENT',
    moduleName: ['master'],
    iconType: 'material-icons-two-tone',
    icon: 'event_note',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    imgSrc: 'assets/images/sidebar-logo/open-arrow.svg',
    imgArrowDownSrc: 'assets/images/sidebar-logo/arrow-bar-down.svg',
    imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
    submenu: [
      {
        path: './master/module/all-modules',
        title: 'System Versus Physical Match',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './master/sub-module/all-sub-modules',
        title: 'Approve Stock Adjustment',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      }
    ]
  },
  {
    path: './',
    title: 'OPENING BALANCE',
    moduleName: ['master'],
    iconType: 'material-icons-two-tone',
    icon: 'event_note',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    imgSrc: 'assets/images/sidebar-logo/open-arrow.svg',
    imgArrowDownSrc: 'assets/images/sidebar-logo/arrow-bar-down.svg',
    imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
    submenu: [
      {
        path: './inventory/trn-inv-ss-opening-balance/all-trn-inv-ss-opening-balances/crud',
        title: 'List of Sub Store Opening Balances',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './inventory/trn-inv-ss-opening-balance/all-trn-inv-ss-opening-balances/approve',
        title: 'Approve Sub Store Opening Balance',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './inventory/trn-inv-sss-opening-balance/all-trn-inv-sss-opening-balances/crud',
        title: 'List of Sub Sub Store Opening Balances',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './inventory/trn-inv-sss-opening-balance/all-trn-inv-sss-opening-balances/approve',
        title: 'Approve Sub Sub Store Opening Balances',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      }
    ]
  },
  {
    path: './',
    title: 'SUPPLIER MANAGEMENT',
    moduleName: ['master'],
    iconType: 'material-icons-two-tone',
    icon: 'event_note',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    imgSrc: 'assets/images/sidebar-logo/open-arrow.svg',
    imgArrowDownSrc: 'assets/images/sidebar-logo/arrow-bar-down.svg',
    imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
    submenu: []
  },
  {
    path: './',
    title: 'GATE PASS',
    moduleName: ['master'],
    iconType: 'material-icons-two-tone',
    icon: 'event_note',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    imgSrc: 'assets/images/sidebar-logo/open-arrow.svg',
    imgArrowDownSrc: 'assets/images/sidebar-logo/arrow-bar-down.svg',
    imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
    submenu: []
  },
  {
    path: './',
    title: 'SUPPLIER MANAGEMENT',
    moduleName: ['master'],
    iconType: 'material-icons-two-tone',
    icon: 'event_note',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    imgSrc: 'assets/images/sidebar-logo/open-arrow.svg',
    imgArrowDownSrc: 'assets/images/sidebar-logo/arrow-bar-down.svg',
    imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
    submenu: [
      {
        path: './inventory/mst-inv-supplier/all-mst-inv-suppliers',
        title: 'List of Suppliers',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      }
    ]
  },
  {
    path: './',
    title: 'MIS',
    moduleName: ['master'],
    iconType: 'material-icons-two-tone',
    icon: 'event_note',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    imgSrc: 'assets/images/sidebar-logo/open-arrow.svg',
    imgArrowDownSrc: 'assets/images/sidebar-logo/arrow-bar-down.svg',
    imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
    submenu: [
      {
        path: './inventory/mis/mis-inv-current-stock',
        title: 'Current Stock',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      }
    ]
  },
  {
    path: '/',
    title: 'MASTER CONFIGURATION',
    moduleName: ['helpdesk-query'],
    iconType: 'material-icons-two-tone',
    icon: 'event_note',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    imgSrc: 'assets/images/sidebar-logo/open-arrow.svg',
    imgArrowDownSrc: 'assets/images/sidebar-logo/arrow-bar-down.svg',
    imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
    submenu: [
      {
        path: './master/mst-inv-item/all-inv-items',
        title: 'Item Configuration',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './master/inv-store/all-inv-stores',
        title: 'Store Configuration',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './master/inv-sub-store/all-inv-sub-stores',
        title: 'Sub-Store Configuration',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './master/inv-sub-sub-store/all-inv-sub-sub-stores',
        title: 'Sub-Sub-Store Configuration',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './master/inv-pkg/all-inv-pkgs',
        title: 'Packaging Configuration',
        moduleName: ['helpdesk-query'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './master/inv-uompurchase/all-inv-uompurchases',
        title: 'UOM(Purchase) Configuration',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './master/inv-uomdispense/all-inv-uomdispenses',
        title: 'UOM(Dispense) Configuration',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './master/inv-brand/all-inv-brands',
        title: 'Brand Configuration',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './master/inv-bbrandcodelink/all-inv-bbrandcodelinks',
        title: 'Brand & Brand Code Configuration',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './master/mst-inv-conversion-factor/all-inv-conversion-factors',
        title: 'Conversion Factor Configuration',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './master/inv-terms-condition/all-inv-terms-conditions',
        title: 'Terms & Condition Configuration',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './master/inv-currency/all-inv-currencys',
        title: 'Currency Configuration',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './master/inv-store-type/all-mst-inv-store-types',
        title: 'Sub Store Type Configuration',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './master/inv-supplier/all-inv-suppliers',
        title: 'Supplier Configuration',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './master/inv-work-order-type/all-inv-work-order-types',
        title: 'Work Order Type',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './master/inv-charge-type/all-mst-inv-charge-types',
        title: 'Charge Type',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './master/inv-gst-percentage/all-mst-inv-gst-percentages',
        title: 'GST Percentage',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './master/inv-current-year/all-mst-inv-current-years',
        title: 'Current Year',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './master/inv-pc-meeting/all-inv-pc-meetings',
        title: 'PC Meeting',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
    ],
  },
  // {
  //   path: './user-guide',
  //   title: 'User Guide',
  //   moduleName: ['master'],
  //   iconType: 'material-icons-two-tone',
  //   icon: 'event_note',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['All'],
  //   imgSrc: 'assets/images/sidebar-logo/open-arrow.svg',
  //   imgArrowDownSrc: 'assets/images/sidebar-logo/arrow-bar-down.svg',
  //   imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
  //   submenu: [
  //     {
  //       path: 'Helpdesk-Query-Uploaded-Images/IOeImsIVyWWPqGWU.jpg',
  //       title: 'Helpdesk Module User Guide',
  //       moduleName: ['master'],
  //       iconType: 'material-icons-two-tone',
  //       icon: 'cloud_upload',
  //       class: '',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: ['All'],
  //       submenu: [],
  //       imgSrc: '',
  //       imgArrowDownSrc: '',
  //       imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
  //     },
  //     // {
  //     //   path: './master/sub-module/all-sub-modules',
  //     //   title: 'Sub Module',
  //     //   moduleName: ['master'],
  //     //   iconType: 'material-icons-two-tone',
  //     //   icon: 'cloud_upload',
  //     //   class: '',
  //     //   groupTitle: false,
  //     //   badge: '',
  //     //   badgeClass: '',
  //     //   role: ['All'],
  //     //   submenu: [],
  //     //   imgSrc: '',
  //     //   imgArrowDownSrc: '',
  //     //   imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
  //     // }
  //   ]
  // },
];
