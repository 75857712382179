import {RouteInfo} from "./sidebar.metadata";

export const StudentRelationshipRoutes: RouteInfo[] = [
  {
    path: '/admin/dashboard/dashboard2',
    title: 'Dashboard',
    moduleName: ['dashboard'],
    iconType: 'material-icons-two-tone',
    imgSrc: 'assets/images/sidebar-logo/open-arrow.svg',
    icon: 'home',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    imgArrowDownSrc: '',
    imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
    submenu: [],
  },
  {
    path: '/student-relationship/student-self-registration/add-student-self-registration/1',
    title: 'Self Registration Form to enroll at SMBT',
    moduleName: ['dashboard'],
    iconType: 'material-icons-two-tone',
    imgSrc: 'assets/images/sidebar-logo/open-arrow.svg',
    icon: 'home',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    imgArrowDownSrc: '',
    imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
    submenu: [],
  },
  {
    path: '/student-relationship/student-self-registration/add-student-self-registration/2',
    title: 'Self Registration Form to upgrade at SMBT',
    moduleName: ['dashboard'],
    iconType: 'material-icons-two-tone',
    imgSrc: 'assets/images/sidebar-logo/open-arrow.svg',
    icon: 'home',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    imgArrowDownSrc: '',
    imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
    submenu: [],
  },
  {
    path: '/student-relationship/student-self-registration/all-student-self-registrations/1/admit',
    title: 'Verify Self Registration Form & Admit Student at SMBT enrollment',
    moduleName: ['dashboard'],
    iconType: 'material-icons-two-tone',
    imgSrc: 'assets/images/sidebar-logo/open-arrow.svg',
    icon: 'home',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    imgArrowDownSrc: '',
    imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
    submenu: [],
  },
  {
    path: '/student-relationship/student-self-registration/all-student-self-registrations/2/admit',
    title: 'Verify Self Registration Form & Admit Student to upgrade',
    moduleName: ['dashboard'],
    iconType: 'material-icons-two-tone',
    imgSrc: 'assets/images/sidebar-logo/open-arrow.svg',
    icon: 'home',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    imgArrowDownSrc: '',
    imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
    submenu: [],
  },
  {
    path: '/student-relationship/trn-std-student/all-trn-std-students',
    title: 'Assign SMBT Student PRN',
    moduleName: ['dashboard'],
    iconType: 'material-icons-two-tone',
    imgSrc: 'assets/images/sidebar-logo/open-arrow.svg',
    icon: 'home',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    imgArrowDownSrc: '',
    imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
    submenu: [],
  },
  {
    path: '/admin/dashboard/dashboard2',
    title: 'Assign Batch',
    moduleName: ['dashboard'],
    iconType: 'material-icons-two-tone',
    imgSrc: 'assets/images/sidebar-logo/open-arrow.svg',
    icon: 'home',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    imgArrowDownSrc: '',
    imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
    submenu: [],
  },
  {
    path: '/admin/dashboard/dashboard2',
    title: 'Assign Hostel Room',
    moduleName: ['dashboard'],
    iconType: 'material-icons-two-tone',
    imgSrc: 'assets/images/sidebar-logo/open-arrow.svg',
    icon: 'home',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    imgArrowDownSrc: '',
    imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
    submenu: [],
  },
  {
    path: '/',
    title: 'Issue Certificate',
    moduleName: ['helpdesk-query'],
    iconType: 'material-icons-two-tone',
    icon: 'event_note',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    imgSrc: 'assets/images/sidebar-logo/open-arrow.svg',
    imgArrowDownSrc: 'assets/images/sidebar-logo/arrow-bar-down.svg',
    imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
    submenu: [
      {
        path: './trn-inv-indent/all-mst-acct-fees',
        title: 'Bonafide Certificate',
        moduleName: ['helpdesk-query'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './helpdesk-query/all-helpdesk-querys',
        title: 'No Dues Certificate',
        moduleName: ['helpdesk-query'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './helpdesk-query/assignee-helpdesk-querys',
        title: 'Expenditure Certificate',
        moduleName: ['helpdesk-query'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './helpdesk-query/my-helpdesk-querys',
        title: 'Hostel Occupancy Certificate',
        moduleName: ['helpdesk-query'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './helpdesk-query/my-helpdesk-querys',
        title: 'Hostel Occupancy Certificate',
        moduleName: ['helpdesk-query'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './helpdesk-query/my-helpdesk-querys',
        title: 'Leaving Certificate',
        moduleName: ['helpdesk-query'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './helpdesk-query/my-helpdesk-querys',
        title: 'Transfer Certificate',
        moduleName: ['helpdesk-query'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './helpdesk-query/my-helpdesk-querys',
        title: 'Attempt Certificate',
        moduleName: ['helpdesk-query'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      }
    ],
  },
  {
    path: './configuration',
    title: 'Master Configuration',
    moduleName: ['student-questionnarie-mark'],
    iconType: 'material-icons-two-tone',
    icon: 'event_note',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    imgSrc: 'assets/images/sidebar-logo/open-arrow.svg',
    imgArrowDownSrc: 'assets/images/sidebar-logo/arrow-bar-down.svg',
    imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
    submenu: [
      {
        path: './master/mst-std-course/all-std-courses',
        title: 'Course',
        moduleName: ['std-course'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './master/mst-std-programme/all-std-programmes',
        title: 'Programme',
        moduleName: ['academic-batch'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './master/mst-std-curriculum-year/all-std-curriculum-years',
        title: 'Curriculum Year',
        moduleName: ['teaching-learning-methodology'],
        iconType: 'material-icons-two-tone',
        icon: 'event_note',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './master/mst-std-admission-year/all-std-admission-years',
        title: 'Admission Year',
        moduleName: ['department'],
        iconType: 'material-icons-two-tone',
        icon: 'event_note',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './master/mst-std-academic-year/all-std-academic-years',
        title: 'Academic Year',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'event_note',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './master/student-document/all-student-documents',
        title: 'Student Document',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'event_note',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './master/student-document-fee-category/all-student-document-fee-categorys',
        title: 'Student Document Fee Category',
        moduleName: ['master'],
        iconType: 'material-icons-two-tone',
        icon: 'event_note',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      }
    ],
  },
  {
    path: '/',
    title: 'MIS',
    moduleName: ['helpdesk-query'],
    iconType: 'material-icons-two-tone',
    icon: 'event_note',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    imgSrc: 'assets/images/sidebar-logo/open-arrow.svg',
    imgArrowDownSrc: 'assets/images/sidebar-logo/arrow-bar-down.svg',
    imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
    submenu: [
      {
        path: './student-relationship/mis/curriculum-year-wise-list-of-student',
        title: 'Curriculum Year wise List of Student',
        moduleName: ['helpdesk-query'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './helpdesk-query/all-helpdesk-querys',
        title: 'Batch wise List Of Student',
        moduleName: ['helpdesk-query'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      },
      {
        path: './helpdesk-query/assignee-helpdesk-querys',
        title: 'Student Profile Layout',
        moduleName: ['helpdesk-query'],
        iconType: 'material-icons-two-tone',
        icon: 'cloud_upload',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
        imgSrc: '',
        imgArrowDownSrc: '',
        imgArrowRightSrc: 'assets/images/sidebar-logo/arrow-right.svg',
      }
    ],
  }
];
