import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {HttpClient, HttpHeaders, HttpEvent, HttpErrorResponse, HttpEventType, HttpParams} from '@angular/common/http';
import {Globalpath} from 'src/app/sharing/globalpath';
// import { MessageService } from 'primeng/api';
import {Router} from '@angular/router';
import {AppError} from './errors/app-error';
// import 'rxjs/add/observable/throw';
import {catchError, map} from 'rxjs/operators';
// import { throwError } from 'rxjs';
// import { Injectable } from '@angular/core';
// import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType} from '@angular/common/http';
// import { throwError } from 'rxjs';
// import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class globalService {
  private globalpath = new Globalpath();
  private path = this.globalpath.path;
  private basepath = this.globalpath.base_path;
  public filePath = this.globalpath.filePath;
  private header = new HttpHeaders();

  // isTblLoading = true;
  constructor(private http: HttpClient) {
    this.header = this.header.set('Content-Type', 'application/json');
    this.header = this.header.set('Authorization', this.globalpath.getToken());
    // console.log("Token => ", this.globalpath.getToken());
  }

  get(url: string, urlParams?: HttpParams): Observable<any> {
    // console.log(this.appConfigService.baseApiPath + url);
    // return this.http.get(this.appConfigService.baseApiPath + url, {headers: this.getHeaders(), params: urlParams});
    // observe: 'response'
    return this.http.get(this.path + url, {
      headers: this.header,
      params: urlParams
    });
    // .pipe(catchError(error => {
    //   // console.log(!error.status);
    //   if (!!error.status && error.status === 401) {
    //     this.getRefreshToken();
    //     // sessionStorage.setItem(this.currentUser.token, resp['token']);
    //     // window.location.href = 'http://localhost:4200/';
    //     return NEVER;            // <-- never emit after the redirect
    //   }
    //   return throwError(error);  // <-- pass on the error if it isn't 401
    // }));
  }

  // get(url, request): Observable<any> {
  //   let userDetails: any = JSON.parse(localStorage.getItem('userDetails'));
  //   request.institute_id = userDetails.institute_id;
  //   return this.http.post(this.path + url + '/get/', JSON.stringify(request), {headers: this.header}).pipe(catchError(this.handleError));
  // }

  getData(url, request): Observable<any> {
    let userDetails: any = JSON.parse(localStorage.getItem('userDetails'));
    request.institute_id = userDetails.institute_id;
    return this.http.get(this.path + url).pipe(catchError(this.handleError));
  }

  getAllData(url, request): Observable<any> {
    let userDetails: any = JSON.parse(localStorage.getItem('userDetails'));
    request.institute_id = userDetails.institute_id;
    return this.http.get(this.basepath + url).pipe(catchError(this.handleError));
  }

  getByObjectId(url, request): Observable<any> {
    let userDetails: any = JSON.parse(localStorage.getItem('userDetails'));
    request.institute_id = userDetails.institute_id;
    return this.http.post(this.path + url + '/getByObjectId/', JSON.stringify(request), {headers: this.header}).pipe(catchError(this.handleError));
  }

  getDistinctByObjectId(url, request): Observable<any> {
    let userDetails: any = JSON.parse(localStorage.getItem('userDetails'));
    request.institute_id = userDetails.institute_id;
    return this.http.post(this.path + url + '/getDistinctByObjectId/', JSON.stringify(request), {headers: this.header}).pipe(catchError(this.handleError));
  }

  getById(url, id): Observable<any> {
    return this.http.post(this.path + url + '/get/' + id, {}, {headers: this.header}).pipe(catchError(this.handleError));
  }

  add(url, data): Observable<any> {
    // let userDetails: any = JSON.parse(localStorage.getItem('userDetails'));
    // data.institute_id = userDetails.institute_id;
    return this.http.post(this.path + url + '/entity', JSON.stringify(data), {headers: this.header}).pipe(map((resp: any) => {
      // console.log("Service Called ==> ");
      // if (resp.operationStatus == 'SUCCESS') {
      return resp;
      // } else if (resp.message == 'Duplicate') {
      //   return resp.message;
      // } else {
      //   return false;
      // }
    }), catchError(this.handleError));
  }

  addWithFullURL(url, data): Observable<any> {
    let userDetails: any = JSON.parse(localStorage.getItem('userDetails'));
    // console.log("Stringify Data => ", JSON.stringify(data));
    // console.log(this.path + url);
    data.institute_id = (userDetails != null && userDetails !== undefined) ? userDetails.institute_id : 1;
    return this.http.post(this.path + url, JSON.stringify(data), {headers: this.header}).pipe(map((resp: any) => {
      // return this.http.post(this.path + url + '/add_record', JSON.stringify(data), {headers:this.header})
      //     // .map( (resp: any) => {
      console.log("Service Called ==> ", resp);
      // if (resp.operationStatus === "SUCCESS") {
      return resp;
      // } else {
      //   return false;
      // }
    }), catchError(this.handleError));
  }

  addArrayData(url, data): Observable<any> {
    let userDetails: any = JSON.parse(localStorage.getItem('userDetails'));
    // console.log("Questionnaire Ans Data => ", data);
    return this.http.post(this.path + url + '/save', data, {headers: this.header}).pipe(map((resp: any) => {
      // console.log(data);
      if (resp.message != undefined && resp.message != null && resp.message != 'Error')
        return resp;
      return false;
    }), catchError(this.handleError));
  }

  addArrayDataFullURL(url, data): Observable<any> {
    let userDetails: any = JSON.parse(localStorage.getItem('userDetails'));
    // console.log("Questionnaire Ans Data => ", data);
    return this.http.post(this.path + url, data, {headers: this.header}).pipe(map((resp: any) => {
      // console.log(data);
      if (resp.message != undefined && resp.message != null && resp.message != 'Error')
        return resp;
      return false;
    }), catchError(this.handleError));
  }

  updateArrayData(url, data): Observable<any> {
    let userDetails: any = JSON.parse(localStorage.getItem('userDetails'));
    // console.log("Header => ", this.header);
    // data.institute_id = userDetails.institute_id;
    return this.http.post(this.path + url + '/update', data, {headers: this.header}).pipe(map((resp: any) => {
      // return this.http.post(this.path + url + '/add_record', JSON.stringify(data), {headers:this.header})
      //     // .map( (resp: any) => {
      // console.log(data);
      if (resp.message != undefined && resp.message != null && resp.message != 'Error')
        return resp;
      return false;
    }), catchError(this.handleError));
  }

  update(url, data): Observable<any> {
    return this.http.post(this.path + url + '/edit_record', JSON.stringify(data), {headers: this.header})
      .pipe(map((resp: any) => {
        if (resp.message != undefined && resp.message != null && resp.message != 'Error')
          return true;
        return false;
      }), catchError(this.handleError));
  }

  patch(url, data): Observable<any> {
    return this.http.patch(this.path + url, JSON.stringify(data), {headers: this.header})
      .pipe(map((resp: any) => {
        if (resp.message != undefined && resp.message != null && resp.message != 'Error')
          return true;
        return false;
      }), catchError(this.handleError));
  }

  put(url: any) {
    return this.http.put(this.path + url, {}, {headers: this.header}).pipe(map((resp: any) => {
      console.log("Service Called ==> ", resp);
      return resp;
    }), catchError(this.handleError));
    // this.http.put(this.path + url, {}, {headers: this.header})
    //   .pipe(map((resp: any) => {
    //     if (resp.message != undefined && resp.message != null && resp.message != 'Error')
    //       return true;
    //     return false;
    //   }), catchError(this.handleError));
  }

  // /*Start File Upload Demo*/
  upload(url, formData) {
    let uploadHeader = new HttpHeaders();
    uploadHeader = uploadHeader.set('Authorization', this.globalpath.getToken());
    return this.http.post<any>(this.path + url + '/save', formData, {
      headers: uploadHeader
    }).pipe(map((resp: any) => {
      // console.log("Upload Document => ", resp);
      if (resp.message !== undefined && resp.message != null && resp.message !== 'Error')
        return resp;
      return false;
    }), catchError(this.handleError));
  }

  uploadWithoutAuth(url, formData) {
    let uploadHeader = new HttpHeaders();
    uploadHeader = uploadHeader.set('Authorization', 'AMOL');
    return this.http.post<any>(this.path + url + '/save', formData, {
      headers: uploadHeader
    }).pipe(map((resp: any) => {
      if (resp.message !== undefined && resp.message != null && resp.message !== 'Error')
        return resp;
      return false;
    }), catchError(this.handleError));
  }

  addWithoutAuth(url, data): Observable<any> {
    let withoutAuthHeader = new HttpHeaders();
    withoutAuthHeader = withoutAuthHeader.set('Content-Type', 'application/json');
    withoutAuthHeader = withoutAuthHeader.set('Authorization', 'AMOL');
    return this.http.post(this.path + url + '/entity', JSON.stringify(data), {headers: withoutAuthHeader}).pipe(map((resp: any) => {
      // console.log("Service Called ==> ");
      // if (resp.message != undefined && resp.message != null && resp.message != 'Error' && resp.message != 'Duplicate') {
      return resp;
      // } else if (resp.message == 'Duplicate') {
      //   return resp.message;
      // } else {
      //   return false;
      // }
    }), catchError(this.handleError));
  }

  editUpload(url, formData) {
    let uploadHeader = new HttpHeaders();
    uploadHeader = uploadHeader.set('Authorization', this.globalpath.getToken());
    return this.http.post<any>(this.path + url + '/edit_record', formData, {
      headers: uploadHeader
    }).pipe(map((resp: any) => {
      if (resp.message != undefined && resp.message != null && resp.message != 'Error')
        return resp;
      return false;
    }), catchError(this.handleError));
  }

  dropdown(url, request): Observable<any> {
    return this.http.get(this.path + url).pipe(
      catchError(this.handleError));
    // .catch(this.handleError);;
  }

  delete(url, id): Observable<any> {
    return this.http.delete(this.path + url + '/trash/' + id, {headers: this.header})
      .pipe(map((resp: any) => {
        if (resp.status)
          return true;
        return false;
      }), catchError(this.handleError));
  }

  updaterecord(url, data): Observable<any> {
    return this.http.post(this.basepath + url, JSON.stringify(data), {headers: this.header})
      .pipe(map((resp: any) => {
        return resp;
      }), catchError(this.handleError));
  }

  updateIdData(url, id, data): Observable<any> {
    let userDetails: any = JSON.parse(localStorage.getItem('userDetails'));
    // console.log("Header => ", this.header);
    return this.http.post(this.path + url + '/update/' + id, data, {headers: this.header}).pipe(map((resp: any) => {
      // console.log(data);
      if (resp.message != undefined && resp.message != null && resp.message != 'Error')
        return resp;
      return false;
    }), catchError(this.handleError));
  }

  updateIdDataWithFullURL(url, id, data): Observable<any> {
    let userDetails: any = JSON.parse(localStorage.getItem('userDetails'));
    // console.log("Header => ", this.header);
    return this.http.post(this.path + url + id, data, {headers: this.header}).pipe(map((resp: any) => {
      // console.log(data);
      if (resp.message != undefined && resp.message != null && resp.message != 'Error')
        return resp;
      return false;
    }), catchError(this.handleError));
  }

  getImageHeaders(): HttpHeaders {
    let headers = new HttpHeaders();
    let token = "123456";
    // headers = headers.append('X-TenantID', sessionStorage.getItem('orgId'));
    if (token !== null) {
      headers = headers.append('Authorization', token);
    }
    return headers;
  }

  postImage(url: string, body: object): Observable<any> {
    return this.http.post(this.path + url, body, {headers: this.getImageHeaders()});
  }

  private handleError(error: Response) {
    // return Observable.throw( new AppError);
    return throwError(new AppError);
  }


  removeFormNullValues(formValues: any) {
    let formKeys = Object.keys(formValues);
    if (formKeys.length) {
      for (let i = 0; i < formKeys.length; i++) {
        if (formValues[formKeys[i]] === null || formValues[formKeys[i]] === undefined) {
          // console.log("IF Block for Form Null Values");
          delete formValues[formKeys[i]];
        } else if (typeof formValues[formKeys[i]] === "object") {
          // console.log("Else Block for Form Null Values");
          let fieldsKeys = Object.keys(formValues[formKeys[i]]);
          if (fieldsKeys.length) {
            // console.log("Else Block for Form Null Values ", fieldsKeys[0]);
            // console.log(formValues[formKeys[i]][fieldsKeys[0]] + " <> " + formValues[formKeys[i]][fieldsKeys[0]]);
            if (formValues[formKeys[i]][fieldsKeys[0]] === null || formValues[formKeys[i]][fieldsKeys[0]] === undefined) {
              // console.log("Amol formValues[formKeys[i]] ", formValues[formKeys[i]]);
              delete formValues[formKeys[i]];
            }
          }
        }
      }
    }
    return formValues;
  }

  removeFormNullValuesFromArray(formValues: any) {
    console.log("formValues 23022023 => ", formValues);
    if (Array.isArray(formValues)) {
      console.log("If BLOCK ", formValues);
      let forms = formValues.length;
      console.log("forms length ", forms);
      for (let i = 0; i < forms; i++) {
        let formKeys = Object.keys(formValues[i]);
        console.log("formKeys.length ", formKeys.length);
        if (formKeys.length) {
          for (let i = 0; i < formKeys.length; i++) {
            // console.log('inside for keys', formKeys[i]);
            if (Array.isArray(formValues[formKeys[i]])) {
              console.log("If Array.isArray => ", formValues[formKeys[i]]);
              if (formValues[formKeys[i]].length === 0) {
                console.log("If formValues[formKeys[i] ", formValues[formKeys[i]]);
                delete formValues[formKeys[i]];
              } else {
                console.log("Else formValues[formKeys[i] ", formValues[formKeys[i]]);
                for (let j = 0; j < formValues[formKeys[i]].length; j++) {
                  let fieldsKeys = Object.keys(formValues[formKeys[i]][j]);
                  console.log(fieldsKeys);
                  for (let k = 0; k < fieldsKeys.length; k++) {
                    // console.log(formValues[formKeys[i]][j][fieldsKeys[k]]);
                    if (!fieldsKeys[k].includes("Id")) {  // && !fieldsKeys[k].includes("Name")
                      console.log("A");
                      delete formValues[formKeys[i]][j][fieldsKeys[k]];
                    } else if (typeof formValues[formKeys[i]][j][fieldsKeys[k]] === 'object') {
                      console.log("B");
                      delete formValues[formKeys[i]][j][fieldsKeys[k]];
                    } else if (Array.isArray(formValues[formKeys[i]][j][fieldsKeys[k]])) {
                      console.log("C");
                      delete formValues[formKeys[i]][j][fieldsKeys[k]];
                    }
                    // if (formValues[formKeys[i]][j][fieldsKeys[k]] == null || formValues[formKeys[i]][j][fieldsKeys[k]] == undefined || typeof formValues[formKeys[i]][j][fieldsKeys[k]] === 'object') {
                    //   delete formValues[formKeys[i]][j][fieldsKeys[k]];
                    // }
                  }
                }
              }
            } else {
              console.log("Else Array.isArray => ", formValues[formKeys[i]]);
              if (formValues[formKeys[i]] === null || formValues[formKeys[i]] === undefined) {
                console.log("Hey 1 ", formValues[formKeys[i]]);
                delete formValues[formKeys[i]];
                console.log("Deleted 1 ", formValues[formKeys[i]]);
                console.log("Deleted 2 ", formValues);
              } else if (typeof formValues[formKeys[i]] === 'object') {
                let fieldsKeys = Object.keys(formValues[formKeys[i]]);
                if (fieldsKeys.length) {
                  for (let j = 0; j < fieldsKeys.length; j++) {
                    if (formValues[formKeys[i]][fieldsKeys[j]] == null || formValues[formKeys[i]][fieldsKeys[j]] === undefined) {
                      console.log("Hey 2 ", formValues[formKeys[i]][fieldsKeys[j]]);
                      delete formValues[formKeys[i]][fieldsKeys[j]];
                      if (Object.keys(formValues[formKeys[i]]).length === 0 || Object.keys(formValues[formKeys[i]]).length === 1) {
                        if (Object.keys(formValues[formKeys[i]]).length === 0) {
                          console.log("Hey 3 ", formValues[formKeys[i]]);
                          delete formValues[formKeys[i]];
                        } else if (Object.keys(formValues[formKeys[i]]).length === 1 && formValues[formKeys[i]][fieldsKeys[j]][0] === null) {
                          console.log("Hey 4 ", formValues[formKeys[i]]);
                          delete formValues[formKeys[i]];
                        }
                      }
                    } else if (typeof formValues[formKeys[i]][fieldsKeys[j]] === 'object') {
                      if (formValues[formKeys[i]][fieldsKeys[j]][0] === null || formValues[formKeys[i]][fieldsKeys[j]][0] === undefined) {
                        // console.log(formValues[formKeys[i]][fieldsKeys[j]]);
                        // delete formValues[formKeys[i]][fieldsKeys[j]];
                        if (Object.keys(formValues[formKeys[i]]).length === 0 || formValues[formKeys[i]][fieldsKeys[j]][0] === null) {
                          if (Object.keys(formValues[formKeys[i]]).length === 0) {
                            console.log("Hey 5 ", formValues[formKeys[i]]);
                            delete formValues[formKeys[i]];
                          } else if (Object.keys(formValues[formKeys[i]]).length === 1 && formValues[formKeys[i]][fieldsKeys[j]][0] === null) {
                            console.log("Hey 6 ", formValues[formKeys[i]]);
                            delete formValues[formKeys[i]];
                          }
                        }
                      }
                    }
                  }
                } else if (formValues[formKeys[i]] === null || formValues[formKeys[i]] === undefined) {
                  console.log(formValues[formKeys[i]]);
                  delete formValues[formKeys[i]];
                }
              }
            }
          }
        }
      }
    } else {
      console.log("ELSE BLOCK ", formValues);
      let formKeys = Object.keys(formValues);
      console.log("Before if ", formKeys.length);
      if (formKeys.length) {
        for (let i = 0; i < formKeys.length; i++) {
          console.log("In for loop ", formKeys[i]);
          // console.log('inside for keys', formKeys[i]);
          if (Array.isArray(formValues[formKeys[i]])) {
            if (formValues[formKeys[i]].length === 0) {
              console.log(formValues[formKeys[i]]);
              console.log('IF fieldsKeys => ', formValues[formKeys[i]]);
              delete formValues[formKeys[i]];
            } else {
              console.log(formValues[formKeys[i]]);
              for (let j = 0; j < formValues[formKeys[i]].length; j++) {
                let fieldsKeys = Object.keys(formValues[formKeys[i]][j]);
                console.log('ELSE fieldsKeys => ', fieldsKeys);
                console.log(fieldsKeys);
                for (let k = 0; k < fieldsKeys.length; k++) {
                  // console.log(formValues[formKeys[i]][j][fieldsKeys[k]]);
                  if (!fieldsKeys[k].includes("Id")) {  // && !fieldsKeys[k].includes("Name")
                    console.log("ABC");
                    delete formValues[formKeys[i]][j][fieldsKeys[k]];
                  } else if (typeof formValues[formKeys[i]][j][fieldsKeys[k]] === 'object') {
                    console.log("ABD");
                    delete formValues[formKeys[i]][j][fieldsKeys[k]];
                  } else if (Array.isArray(formValues[formKeys[i]][j][fieldsKeys[k]])) {
                    console.log("ABE");
                    delete formValues[formKeys[i]][j][fieldsKeys[k]];
                  }
                  // if (formValues[formKeys[i]][j][fieldsKeys[k]] == null || formValues[formKeys[i]][j][fieldsKeys[k]] == undefined || typeof formValues[formKeys[i]][j][fieldsKeys[k]] === 'object') {
                  //   delete formValues[formKeys[i]][j][fieldsKeys[k]];
                  // }
                }
              }
            }

          } else {
            if (formValues[formKeys[i]] === null || formValues[formKeys[i]] === undefined) {
              console.log(formValues[formKeys[i]]);
              delete formValues[formKeys[i]];
            } else if (typeof formValues[formKeys[i]] === 'object') {
              let fieldsKeys = Object.keys(formValues[formKeys[i]]);
              if (fieldsKeys.length) {
                for (let j = 0; j < fieldsKeys.length; j++) {
                  if (formValues[formKeys[i]][fieldsKeys[j]] == null || formValues[formKeys[i]][fieldsKeys[j]] === undefined) {
                    console.log(formValues[formKeys[i]][fieldsKeys[j]]);
                    delete formValues[formKeys[i]][fieldsKeys[j]];
                    if (Object.keys(formValues[formKeys[i]]).length === 0 || Object.keys(formValues[formKeys[i]]).length === 1) {
                      if (Object.keys(formValues[formKeys[i]]).length === 0) {
                        console.log(formValues[formKeys[i]]);
                        delete formValues[formKeys[i]];
                      } else if (Object.keys(formValues[formKeys[i]]).length === 1 && formValues[formKeys[i]][fieldsKeys[j]][0] === null) {
                        console.log(formValues[formKeys[i]]);
                        delete formValues[formKeys[i]];
                      }
                    }
                  } else if (typeof formValues[formKeys[i]][fieldsKeys[j]] === 'object') {
                    if (formValues[formKeys[i]][fieldsKeys[j]][0] === null || formValues[formKeys[i]][fieldsKeys[j]][0] === undefined) {
                      // console.log(formValues[formKeys[i]][fieldsKeys[j]]);
                      // delete formValues[formKeys[i]][fieldsKeys[j]];
                      if (Object.keys(formValues[formKeys[i]]).length === 0 || formValues[formKeys[i]][fieldsKeys[j]][0] === null) {
                        if (Object.keys(formValues[formKeys[i]]).length === 0) {
                          console.log(formValues[formKeys[i]]);
                          delete formValues[formKeys[i]];
                        } else if (Object.keys(formValues[formKeys[i]]).length === 1 && formValues[formKeys[i]][fieldsKeys[j]][0] === null) {
                          console.log(formValues[formKeys[i]]);
                          delete formValues[formKeys[i]];
                        }
                      }
                    }
                  }
                }
              } else if (formValues[formKeys[i]] === null || formValues[formKeys[i]] === undefined) {
                console.log(formValues[formKeys[i]]);
                delete formValues[formKeys[i]];
              }
            }
          }
        }
      }
    }

    return formValues;
  }
}
