export enum Role {
  All = 'All',
  Admin = 'Admin',
  Teacher = 'Teacher',
  Faculty = 'Faculty',
  Student = 'Student',
  Hoi = 'HOI',
  Clerk = 'Clerk',
  AcademicIncharge = 'Academic Incharge',
  StudentSection = 'Student Section',
  DeptHOD = "Dept HOD",
  EndUserComplaint = "End User -Complaint",
}
