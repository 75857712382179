import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

const routes: Routes = [
  {
    path: 'role',
    loadChildren: () =>
      import('./role/role.module').then((m) => m.RoleModule),
  },
  {
    path: 'action',
    loadChildren: () =>
      import('./actions/action.module').then((m) => m.ActionModule),
  },
  // {
  //   path: 'doc-type',
  //   loadChildren: () =>
  //     import('./doc-type/doc-type.module').then((m) => m.DocTypeModule),
  // },
  // {
  //   path: 'doc-file-type',
  //   loadChildren: () =>
  //     import('./doc-file-type/doc-file-type.module').then((m) => m.DocFileTypeModule),
  // },
  // {
  //   path: 'doc-type',
  //   loadChildren: () =>
  //     import('./doc-type/doc-type.module').then((m) => m.DocTypeModule),
  // },
  // {
  //   path: 'doc-dt-institute-template',
  //   loadChildren: () =>
  //     import('./doc-dt-institute-template/doc-dt-institute-template.module').then((m) => m.DocDtInstituteTemplateModule),
  // },
  {
    path: 'doa-amount',
    loadChildren: () =>
      import('./doa-amount/doa-amount.module').then((m) => m.DoaAmountModule),
  },
  {
    path: 'student-document',
    loadChildren: () =>
      import('./student-document/student-document.module').then((m) => m.StudentDocumentModule),
  },
  {
    path: 'fee-category',
    loadChildren: () =>
      import('./fee-category/fee-category.module').then((m) => m.FeeCategoryModule),
  },
  {
    path: 'student-document-fee-category',
    loadChildren: () =>
      import('./student-document-fee-category/student-document-fee-category.module').then((m) => m.StudentDocumentFeeCategoryModule),
  },
  {
    path: 'role-action',
    loadChildren: () =>
      import('./role-action/role-action.module').then((m) => m.RoleActionModule),
  },
  {
    path: 'user-role-action',
    loadChildren: () =>
      import('./user-role-action/user-role-action.module').then((m) => m.UserRoleActionModule),
  },
  // {
  //   path: 'complaint-building',
  //   loadChildren: () =>
  //     import('./complaint-building/complaint-building.module').then((m) => m.ComplaintBuildingModule),
  // },
  // {
  //   path: 'complaint-office',
  //   loadChildren: () =>
  //     import('./complaint-office/complaint-office.module').then((m) => m.ComplaintOfficeModule),
  // },
  // {
  //   path: 'template-configuration',
  //   loadChildren: () =>
  //     import('./template-configuration/template-configuration.module').then((m) => m.TemplateConfigurationModule),
  // },
  // {
  //   path: 'doc-material-status',
  //   loadChildren: () =>
  //     import('./doc-material-status/doc-material-status.module').then((m) => m.DocMaterialStatusModule),
  // },
  {
    path: 'module',
    loadChildren: () =>
      import('./module/modules.module').then((m) => m.ModulesModule),
  },
  {
    path: 'sub-module',
    loadChildren: () =>
      import('./sub-module/sub-module.module').then((m) => m.SubModuleModule),
  },
  {
    path: 'inv-terms-condition',
    loadChildren: () =>
      import('./mst-inv-terms-condition/inv-terms-condition.module').then((m) => m.InvTermsConditionModule),
  },
  {
    path: 'inv-uomdispense',
    loadChildren: () =>
      import('./mst-inv-uomdispense/inv-uomdispense.module').then((m) => m.InvUomdispenseModule),
  },
  {
    path: 'inv-uompurchase',
    loadChildren: () =>
      import('./mst-inv-uompurchase/inv-uompurchase.module').then((m) => m.InvUompurchaseModule),
  },
  {
    path: 'inv-pkg',
    loadChildren: () =>
      import('./mst-inv-pkg/inv-pkg.module').then((m) => m.InvPkgModule),
  },
  {
    path: 'inv-brand',
    loadChildren: () =>
      import('./mst-inv-brand/inv-brand.module').then((m) => m.InvBrandModule),
  },
  {
    path: 'inv-store',
    loadChildren: () =>
      import('./mst-inv-store/inv-store.module').then((m) => m.InvStoreModule),
  },
  {
    path: 'inv-sub-store',
    loadChildren: () =>
      import('./mst-inv-sub-store/inv-sub-store.module').then((m) => m.InvSubStoreModule),
  },
  {
    path: 'inv-sub-sub-store',
    loadChildren: () =>
      import('./mst-inv-sub-sub-store/inv-sub-sub-store.module').then((m) => m.InvSubSubStoreModule),
  },
  {
    path: 'inv-bbrandcodelink',
    loadChildren: () =>
      import('./mst-inv-bbrandcodelink/inv-bbrandcodelink.module').then((m) => m.InvBbrandCodeLinkModule),
  },
  {
    path: 'inv-currency',
    loadChildren: () =>
      import('./mst-inv-currency/mst-inv-currency.module').then((m) => m.MstInvCurrencyModule),
  },
  {
    path: 'inv-store-type',
    loadChildren: () =>
      import('./mst-inv-store-type/inv-store-type.module').then((m) => m.InvStoreTypeModule),
  },
  {
    path: 'inv-charge-type',
    loadChildren: () =>
      import('./mst-inv-charge-type/mst-inv-charge-type.module').then((m) => m.MstInvChargeTypeModule),
  },
  {
    path: 'inv-gst-percentage',
    loadChildren: () =>
      import('./mst-inv-gst-percentage/mst-inv-gst-percentage.module').then((m) => m.MstInvGstPercentageModule),
  },
  {
    path: 'inv-supplier',
    loadChildren: () =>
      import('./mst-inv-supplier/inv-supplier.module').then((m) => m.InvSupplierModule),
  },
  {
    path: 'mst-inv-conversion-factor',
    loadChildren: () =>
      import('./mst-inv-conversion-factor/inv-conversion-factor.module').then((m) => m.InvConversionFactorModule),
  },
  {
    path: 'inv-section',
    loadChildren: () =>
      import('./inv-section/inv-section.module').then((m) => m.InvSectionModule),
  },
  {
    path: 'inv-department',
    loadChildren: () =>
      import('./inv-department/inv-department.module').then((m) => m.InvDepartmentModule),
  },
  {
    path: 'inv-speciality',
    loadChildren: () =>
      import('./inv-speciality/inv-speciality.module').then((m) => m.InvSpecialityModule),
  },
  {
    path: 'inv-super-speciality',
    loadChildren: () =>
      import('./inv-super-speciality/inv-super-speciality.module').then((m) => m.InvSuperSpecialityModule),
  },
  {
    path: 'inv-class',
    loadChildren: () =>
      import('./inv-class/inv-class.module').then((m) => m.InvClassModule),
  },
  {
    path: 'inv-sub-class',
    loadChildren: () =>
      import('./inv-sub-class/inv-sub-class.module').then((m) => m.InvSubClassModule),
  },
  {
    path: 'inv-category',
    loadChildren: () =>
      import('./inv-category/inv-category.module').then((m) => m.InvCategoryModule),
  },
  {
    path: 'inv-sub-category',
    loadChildren: () =>
      import('./inv-sub-category/inv-sub-category.module').then((m) => m.InvSubCategoryModule),
  },
  {
    path: 'mst-inv-item',
    loadChildren: () =>
      import('./mst-inv-item/inv-items.module').then((m) => m.InvItemModule),
  },
  {
    path: 'mst-std-course',
    loadChildren: () =>
      import('./mst-std-course/std-course.module').then((m) => m.StdCourseModule),
  },
  {
    path: 'mst-std-programme',
    loadChildren: () =>
      import('./mst-std-programme/std-programme.module').then((m) => m.StdProgrammeModule),
  },
  {
    path: 'mst-std-curriculum-year',
    loadChildren: () =>
      import('./mst-std-curriculum-year/std-curriculum-year.module').then((m) => m.StdCurriculumYearModule),
  },
  {
    path: 'mst-std-admission-year',
    loadChildren: () =>
      import('./mst-std-admission-year/std-admission-year.module').then((m) => m.StdAdmissionYearModule),
  },
  {
    path: 'mst-std-academic-year',
    loadChildren: () =>
      import('./mst-std-academic-year/std-academic-year.module').then((m) => m.StdAcademicYearModule),
  },
  {
    path: 'std-class-type',
    loadChildren: () =>
      import('./mst-std-class-type/std-class-type.module').then((m) => m.StdClassTypeModule),
  },
  {
    path: 'mst-acct-fee',
    loadChildren: () =>
      import('./mst-acct-fee/mst-acct-fee.module').then((m) => m.MstAcctFeeModule),
  },
  {
    path: 'inv-work-order-type',
    loadChildren: () =>
      import('./mst-inv-work-order-type/inv-work-order-type.module').then((m) => m.InvWorkOrderTypeModule),
  },
  {
    path: 'inv-current-year',
    loadChildren: () =>
      import('./mst-inv-current-year/mst-inv-current-year.module').then((m) => m.MstInvCurrentYearModule),
  },
  {
    path: 'inv-pc-meeting',
    loadChildren: () =>
      import('./mst-inv-pc-meeting/inv-pc-meeting.module').then((m) => m.InvPcMeetingModule),
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})

export class MasterRoutingModule {
}
